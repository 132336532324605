import React from "react"
import { graphql } from "gatsby"

import Header from "../components/partials/header"
import LayoutV2 from "../components/v2/layout-no-padding-site-main"
import SEO from "../components/seo"
import rehypeReact from "rehype-react"
import banners from "../components/banner-imports"
import bannervideos from "../components/video-imports"
import VideoCover from "react-video-cover"
import FullDivScreen from "react-div-100vh"
import glanceImg1 from "../utils/svg/glance-1.svg"
import glanceImg2 from "../utils/svg/glance-2.svg"
import glanceImg3 from "../utils/svg/glance-3.svg"

import APIHelper from "../utils/helpers/api"

import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax"

import { ContactDiv } from "../components/partials/page"
import {
  Section,
  SubSection,
  StatCardList,
  StatCard,
  AccordionList,
  AccordionListItem,
  ArrowList,
  ArrowListItem,
  BoxList,
  BoxListItem,
  PLink,
} from "../components/partials/base"

import "../utils/css/components/expertise.css"

// const tabletWidth = 1024

const glanceImages = {
  glanceImg1: glanceImg1,
  glanceImg2: glanceImg2,
  glanceImg3: glanceImg3,
}

let GlobalState = {
  PageContent: {},
  loaded: null,
}

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.parChild = React.createRef()
    this.parChildDetails = React.createRef()
    this.state = {
      windowHeight: null,
      windowWidth: null,
      ScrollCount: null,
      loaded: false,
      isError: false,
      isMobile: null,
      currentExpertise: null,
    }
    this.listenToScroll = this.listenToScroll.bind(this)
    this.bannerCover = React.createRef()
  }

  async componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll)
    this.setState({
      windowWidth: window.screen.width,
      windowHeight: window.screen.height,
    })

    let { key = "boilerplate" } = this.props.data.markdownRemark.frontmatter

    if (key == null) {
      key = "boilerplate"
    }

    APIHelper.Get(`/contents/expertise/${key}?json=true`, (err, response) => {
      if (err) {
        this.setState({ isError: true })
        console.log(`Something bad happened while fetching the data\n${err}`)
      } else {
        GlobalState.PageContent = response.data
        GlobalState.loaded = true
        this.setState({ loaded: true })
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll)
    this.setState({
      windowWidth: window.screen.width,
      windowHeight: window.screen.height,
    })
  }
  // onResize = (width, height) => {
  //   this.setState({
  //     windowWidth: width,
  //     windowHeight: height,
  //   })

  // }
  listenToScroll = () => {
    var winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > 300 && winScroll < 500) {
      // this.hideBannerDiv(this.parChild.current)
    } else if (winScroll < 300 && winScroll > 200) {
      // this.showBannerDiv(this.parChild.current)
    }

    if (winScroll > 150 && winScroll < 300) {
      // this.hideBannerDiv(this.parChildDetails.current)
    } else if (winScroll < 150 && winScroll > 100) {
      // this.showBannerDiv(this.parChildDetails.current)
    }
  }
  hideBannerDiv = elem => {
    this.animateDiv(elem, "fadeOut")
    elem.style.opacity = 0
  }
  showBannerDiv = elem => {
    this.animateDiv(elem, "fadeIn")
    elem.style.opacity = 1
  }
  animateDiv = (elem, animation) => {
    // if(this.state.ScrollCount >600){
    elem.classList.remove("fadeIn")
    elem.classList.remove("fadeOut")
    elem.classList.add("animated")
    elem.classList.add(animation)
    setTimeout(() => {
      elem.classList.remove("animated")
      elem.classList.remove(animation)
    }, 1000)
  }
  render() {
    const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: {
        "banner-intro": BannerIntro,
        "intro-stats": IntroStats,
        "parallax-banner": PBanner,
        "banner-filler": BannerFiller,
        "banner-footer": BannerFooter,
        "expertise-section": ExpertiseRoles,
        "contact-section": ContactSection,
        "stats-section": StatsSection,
        "accordion-section": AccordionSection,
        "boxes-section": BoxesSection,
      },
    }).Compiler

    const post = this.props.data.markdownRemark

    const Scrollindicator = () => {
      return (
        <div>
          <div className="icon-swipe"></div>
        </div>
      )
    }
    const phoneWidth = 768
    const tabletWidth = 950
    var bgImageSelector = post.frontmatter.bgcover
    var isMobile = false
    const loop = post.frontmatter.loopkey
    var videoStyle = {
      position: "relative",
      objectFit: "cover",
      width: "100vw",
      height: "100vh",
      zIndex: "-1",
    }
    const width = this.state.windowWidth
    const height = this.state.windowHeight
    const contentSelector = () => {
      if (width) {
        if (width >= tabletWidth) {
          bgImageSelector = `b${bgImageSelector}`
        } else if (width >= phoneWidth && width <= tabletWidth) {
          //landscape check
          if (width >= 300 && width <= 990) {
            if (height >= 150 && height <= 600) {
              bgImageSelector = `b${bgImageSelector}`
            } else {
              isMobile = true
              bgImageSelector = `mb${bgImageSelector}`
            }
          } else {
            isMobile = true
            bgImageSelector = `mb${bgImageSelector}`
          }
        } else if (width <= phoneWidth) {
          //landscape check
          if (width >= 300 && width <= 990) {
            if (height >= 150 && height <= 600) {
              bgImageSelector = `b${bgImageSelector}`
            } else {
              isMobile = true
              bgImageSelector = `mb${bgImageSelector}`
            }
          } else {
            isMobile = true
            bgImageSelector = `mb${bgImageSelector}`
          }
        } else {
          bgImageSelector = `b${bgImageSelector}`
        }
      } else {
        bgImageSelector = `b${bgImageSelector}`
      }
      if (isMobile) {
        videoStyle["objectPosition"] = "center -4.8vh"
        videoStyle["transform"] = "scale(1.1)"
      }
    }
    const CoverContent = () => {
      if (isMobile) {
        return (
          <div
            style={{
              background: `url("${banners[bgImageSelector]}")`,
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
              backgroundRepeat: "no-repeat",
              transform: "scale(1.1)",
              backgroundPosition: "center -4.8vh",
              height: "100vh",
              width: "100vw",
              zIndex: "-2",
              position: "relative",
            }}
          ></div>
        )
      } else {
        return (
          <VideoCover
            style={videoStyle}
            videoOptions={{
              src: bannervideos[bgImageSelector],
              autoPlay: true,
              muted: true,
              loop: loop,
            }}
          />
        )
      }
    }
    const ExpertiseCover = () => {
      contentSelector()

      return (
        <div className="cover-filler animated fadeIn">
          <FullDivScreen>
            <CoverContent />
          </FullDivScreen>
          <div className="parallaxChildren" ref={this.parChild}>
            <h1>{post.frontmatter.title}</h1>
          </div>
          <ul className="parallaxBannerDetails" ref={this.parChildDetails}>
            <li>
              <PLink
                to={`distributed-teams`}
                onClickFadeOut={true}
                ignoreScrollTop={true}
              >
                Distributed Teams
              </PLink>
            </li>
            <li>
              <PLink to={`/`} onClickFadeOut={true} ignoreScrollTop={true}>
                Expertise
              </PLink>
            </li>
          </ul>
          <div class="line-expertise"></div>
          <Scrollindicator />
        </div>
      )
    }
    const ExpertiseLayout = () => {
      if (GlobalState.loaded) {
        return (
          <LayoutV2
            location={this.props.location}
            title={siteTitle}
            headerActive={`Expertise`}
          >
            <SEO
              title={post.frontmatter.title}
              description={post.frontmatter.description || post.excerpt}
            />
            <ParallaxProvider>
              <ExpertiseCover>
                <BannerFiller height="100vh" />
              </ExpertiseCover>
              <div className="indexed-hover">
                <div className="expertise-contents site-main">
                  {this.state.loaded ? renderAst(post.htmlAst) : <></>}
                </div>
              </div>
            </ParallaxProvider>
          </LayoutV2>
        )
      } else {
        return (
          <div id="site-wrapper" className="site-wrapper">
            <Header headerActive={"idle"} location={"idle"}></Header>
          </div>
        )
      }
    }
    const siteTitle = this.props.data.site.siteMetadata.title

    return <ExpertiseLayout />
  }
}

class BannerIntro extends React.Component {
  render() {
    const { content } = this.props
    const BannerContent = GlobalState.PageContent[content]
    return (
      <section className="expertise-intro">
        <span>{BannerContent.sidetitle}</span>
        <div className="expertise-intro-details">
          <p>{BannerContent.hero}</p>
          <div className="expertise-more-details">
            <p>{BannerContent.subhero_a}</p>
            <p>{BannerContent.subhero_b}</p>
          </div>
        </div>
      </section>
    )
  }
}
class IntroStats extends React.Component {
  render() {
    const { content } = this.props,
      StatsContent = GlobalState.PageContent[content]
    return (
      <section className="intro-summary">
        <div></div>
        <div className="intro-section">
          {StatsContent.map(({ number, label }) => {
            return (
              <div>
                <h3>{number}</h3>
                <div>
                  <p>{label}</p>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    )
  }
}
export class PBanner extends React.Component {
  render() {
    return (
      <ParallaxBanner
        className="bannerClass"
        layers={[
          {
            image: banners[this.props.cover_image],
            amount: 0.2,
          },
        ]}
        style={{
          // height: this.state.windowHeight,
          // width: this.state.windowWidth,

          height: "70vh",
          width: "100vw",
          opacity: 1,
          maxWidth: "-webkit-fill-available",
        }}
      />
    )
  }
}
export class BannerFiller extends React.Component {
  render() {
    return (
      <div
        className="bannerFiller"
        style={{
          position: "relative",
          top: "-6.5rem",
          left: "50%",
          // width: "calc(100vw - 15px)",
          width: "calc(100vw - var(--scroll-bar))",
          height: this.props.height,
          WebkitTransform: "translateX(-50%)",
          transform: "translateX(-50%)",
          zIndex: -10,
        }}
      ></div>
    )
  }
}
class BannerFooter extends React.Component {
  render() {
    const linkClick = () => {
      GlobalState.loaded = false
    }
    return (
      <div className="bannerFooter">
        <PLink
          to={this.props.next_expertise_link}
          onClickFadeOut={true}
          ignoreScrollTop={true}
          onClick={linkClick()}
        >
          <h2>{this.props.next_expertise}</h2>
        </PLink>
        <h3>Next Expertise ></h3>
      </div>
    )
  }
}
class ExpertiseRoles extends React.Component {
  render() {
    return (
      <div className="bannerRoles">
        <header>
          <h2 className="bannerRolesHeader">Execution (Roles)</h2>
          <span></span>
        </header>
        <div className="bannerRolesContent">
          <ul className="bannerRolesInner">
            <li>
              <h3>
                <span>Delivery Lead </span>
              </h3>
              <p>
                Before building anything, we work to understand the who, what,
                and why. Who are we building for? What is the problem we’re
                solving? And why is this the solution they need? Having answers
                to these questions early in the process allows us to build
                products that enhance capabilities, bring happiness, and
                simplify process.
              </p>
            </li>
            <li>
              <h3>
                <span>Junior</span> Developer
              </h3>
              <p>
                Trends come and go, especially when it comes to technology. This
                is why we rely on research, user insights, best practices, and
                years of experience to guide us in developing every part of your
                product. Our goal isn’t to create something fleeting, but
                something that will stand the test of time as user’s needs and
                technology change.
              </p>
            </li>
            <li>
              <h3>
                <span>Developer</span>
              </h3>
              <p>
                Getting input from users and stakeholders at every stage of the
                process is key to building a successful product. By constantly
                checking in and validating assumptions, we ensure that your
                product will not only be useful, but met with joy and
                anticipation.
              </p>
            </li>
          </ul>
          <ul className="bannerRolesInner">
            <li>
              <h3>
                <span>Senior</span> Developer
              </h3>
              <p>
                Before building anything, we work to understand the who, what,
                and why. Who are we building for? What is the problem we’re
                solving? And why is this the solution they need? Having answers
                to these questions early in the process allows us to build
                products that enhance capabilities, bring happiness, and
                simplify process.
              </p>
            </li>
            <li>
              <h3>
                <span>Quality Assurance</span> Manager
              </h3>
              <p>
                Trends come and go, especially when it comes to technology. This
                is why we rely on research, user insights, best practices, and
                years of experience to guide us in developing every part of your
                product. Our goal isn’t to create something fleeting, but
                something that will stand the test of time as user’s needs and
                technology change.
              </p>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
class ContactSection extends React.Component {
  render() {
    const props = this.props
    return (
      <ContactDiv
        sectionName={props.sectionname}
        sectionClassName={props.sectionclassname}
        onClickFadeOut={true}
        ignoreScrollTop={true}
      ></ContactDiv>
    )
  }
}
class StatsSection extends React.Component {
  render() {
    const { sectionname, sectionclassname, content } = this.props,
      stats = GlobalState.PageContent[content]

    return (
      <Section sectionName={sectionname} sectionClassName={sectionclassname}>
        <SubSection style={{ marginTop: `0px` }}>
          <StatCardList
            style={{ marginTop: `0px` }}
            className="expertise-stat-card"
          >
            {stats.map(
              ({ number, emphasizedDesc, normalDescription }, index) => {
                return (
                  <StatCard
                    number={number}
                    emphasizedDesc={emphasizedDesc}
                    normalDescription={normalDescription}
                    key={`stat-card-${index}`}
                  ></StatCard>
                )
              }
            )}
          </StatCardList>
        </SubSection>
      </Section>
    )
  }
}
class AccordionSection extends React.Component {
  render() {
    const { sectionname, sectionclassname, content } = this.props,
      listcontent = GlobalState.PageContent[content]
    return (
      <Section sectionName={sectionname} sectionClassName={sectionclassname}>
        <SubSection style={{ marginTop: `0px` }}>
          <AccordionList>
            {listcontent.map(({ title, list, image }) => {
              const toggleHtml = (
                <>
                  <span className="accordion-list-item-toggle-span">
                    {title}
                  </span>
                </>
              )
              return (
                <AccordionListItem toggleHtml={toggleHtml}>
                  <div className="row">
                    <div className="col-sm-12 glance-item-row">
                      <div className="glance-img">
                        <img alt="key" src={glanceImages[image]} />
                      </div>
                      <div className="glance-list">
                        <ArrowList>
                          {list.map(item => {
                            return <ArrowListItem>{item}</ArrowListItem>
                          })}
                        </ArrowList>
                      </div>
                    </div>
                  </div>
                </AccordionListItem>
              )
            })}
          </AccordionList>
        </SubSection>
      </Section>
    )
  }
}

class BoxesSection extends React.Component {
  render() {
    const { sectionname, sectionclassname, content } = this.props,
      listcontent = GlobalState.PageContent[content]
    return (
      <Section sectionName={sectionname} sectionClassName={sectionclassname}>
        <SubSection>
          <BoxList>
            {listcontent.map(({ highlighted, normal }) => {
              return (
                <BoxListItem>
                  {" "}
                  <span className="expertise-role-highlighted">
                    {" "}
                    {highlighted}{" "}
                  </span>{" "}
                  <span className="expertise-role-normal"> {normal} </span>
                </BoxListItem>
              )
            })}
          </BoxList>
        </SubSection>
      </Section>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query ExpertiseBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        api {
          url
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        bgcover
        key
        loopkey
      }
    }
  }
`
