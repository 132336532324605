import React from "react"
import { graphql, StaticQuery } from "gatsby"

// Pages
import LayoutV2 from "../components/v2/layout-no-padding-site-main"
import { BannerFiller, PBanner } from "../templates/expertise"
import SEO from "../components/seo"
import banners from "../components/banner-imports"
import FullDivScreen from "react-div-100vh"
// import videos from "../components/video-imports"
import VideoCover from "react-video-cover"
import bannervideos from "../components/video-imports"
// import { imageSelector } from "../components/image-resize-helper"
import { ParallaxProvider } from "react-scroll-parallax"
import { ContactDivDteams } from "../components/partials/page"
import "../utils/normalize.css"
import "../utils/css/v2.css"

import Img1 from "../../content/assets/new_engagement_models_expanded.svg"

// Partials
import {
  Section,
  PLink,
} from "../components/partials/base"
const phoneWidth = 768
const tabletWidth = 950
var isMobile = false
const DistributedTeams = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <LayoutV2 location={location} title={siteTitle} headerActive={`Expertise`}>
      <SEO title={`Distributed Teams`} keywords={[]} />
      <ParallaxProvider>
        <BannerCover />
        <div className="indexed-hover">
          <article className="psg-section post-content page-template no-image site-main">
            <BannerFiller height="10vh" />
            <section className="expertise-intro">
              <span>Introduction</span>
              <div className="expertise-intro-details">
                <p>
                  Building distributed teams is a great way to access larger
                  talent pools, maintain full operational control, foster trust
                  and scale faster
                </p>
                <div className="expertise-more-details">
                  <p>
                    Distributed teams (also called remote teams) are a group of
                    employees dispersed geographically around the globe rather
                    than being centalised at a single headquarters. As
                    communicating with online collaboration software and
                    videoconferencing becomes the norm and widespread talent
                    shortages fuel the war for talent;
                  </p>
                  <p>
                    organisations are rethinking their talent strategies to
                    incorporate globally distributed teams that are
                    geographically dispersed to access greater critical talent
                    pools as a necessary way to meet global strategic outcomes.
                  </p>
                </div>
              </div>
            </section>
            <BannerFiller height="10vh" />
            <section className="intro-summary">
              <div></div>
              <div className="intro-section">
                <div>
                  <h3>83% </h3>
                  <div>
                    <p>of execs say talent acquisition is very important</p>
                  </div>
                </div>
                <div>
                  <h3>72%</h3>
                  <div>
                    <p>of execs see virtual teaming capabilities as critical</p>
                  </div>
                </div>
                <div>
                  <h3>65%</h3>
                  <div>
                    <p>
                      of employees see innovation and collaboration across
                      locations
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <PBanner cover_image="distriteamparallax"></PBanner>
            <BannerFiller height="10vh" />
            <section className="expertise-intro">
              <span> Remote</span>
              <div className="expertise-intro-details">
                <p>Outsourcing vs Distributed Teams</p>
                <div className="expertise-more-details">
                  <p>
                    Distributed teams are the next evolution in outsourcing
                    where the key driver is no longer cost but control and
                    talent. With traditional outsourcing models, an outsourcing
                    business will be contracted to deliver a piece of work or
                    project and provide little-to-no access to the people
                    actually doing the work. Whereas distributed teams are
                    employed as an extension of your local team that work out of
                    our amazing offices in the Philippines. We can even fit out
                    the space to look and feel like your local office.
                  </p>
                  <p>
                    The employees are integrated into your company's induction,
                    culture and policies. You are given full control over
                    selecting the employees, the hours they work, how much
                    they're paid, the level of benefits etc. These employees are
                    managed directly by your business with our assistance. We
                    provide best practices on how to build a successful
                    long-term distributed team, along with our full and
                    continual support. Like any true partnership, you are given
                    multiple contact points from our CEO, to HR, recruitment,
                    legal, networking and finance teams - we are here to help at
                    every stage.
                  </p>
                </div>
              </div>
            </section>
            <BannerFiller height="10vh" />
            <Section sectionName="At A Glance" sectionClassName="bannerRoles">
              <div className="distributed-teams--models-content">
                <div className="distributed-teams--models-text">
                  <span>How the different models stack up</span>
                </div>
                <div className="distributed-teams--models-img">
                  <img src={Img1} alt=""/>
                </div>
              </div>
            </Section>
            <ContactDivDteams
              sectionName="Contact"
              sectionClassName="section-div"
              onClickFadeOut={true}
              ignoreScrollTop={true}
            ></ContactDivDteams>
            <BannerFiller height="10vh" />
          </article>
        </div>
      </ParallaxProvider>
    </LayoutV2>
  )
}
class BannerCover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      windowHeight: null,
      windowWidth: null,
    }
  }
  componentDidMount() {
    this.setState({
      windowWidth: window.screen.width,
      windowHeight: window.screen.height,
    })
  }
  componentWillUnmount() {
    this.setState({
      windowWidth: window.screen.width,
      windowHeight: window.screen.height,
    })
  }
  render() {
    const contentSelector = () => {
      var width = this.state.windowWidth
      var height = this.state.windowHeight
      if (width) {
        if (width >= tabletWidth) {
        } else if (width >= phoneWidth && width <= tabletWidth) {
          if (width >= 300 && width <= 990) {
            if (height >= 150 && height <= 600) {
            } else {
              isMobile = true
            }
          } else {
            isMobile = true
          }
        } else if (width <= phoneWidth) {
          //landscape check
          if (width >= 300 && width <= 990) {
            if (height >= 150 && height <= 600) {
            } else {
              isMobile = true
            }
          } else {
            isMobile = true
          }
        }
      }
    }
    const CoverContent = () => {
      if (isMobile) {
        return (
          <div
            style={{
              background: `url("${banners["mbg0"]}")`,
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              height: "100vh",
              width: "100vw",
              zIndex: "-2",
              position: "relative",
            }}
          ></div>
        )
      } else {
        return (
          <VideoCover
            style={{
              position: "relative",
              objectFit: "cover",
              width: "100vw",
              height: "100vh",
              zIndex: "-2",
            }}
            videoOptions={{
              src: bannervideos["bg0"],
              autoPlay: true,
              muted: true,
              loop: false,
            }}
          />
        )
      }
    }
    const BannerContent = () => {
      contentSelector()
      return (
        <div className="cover-filler ">
          <FullDivScreen>
            <CoverContent />
          </FullDivScreen>
          <div className="parallaxChildren">
            <h1 className="distriteamstitle">Distributed Teams</h1>
          </div>
          <ul className="parallaxBannerDetails distriteamslink">
            <li>
              <PLink
                to={`distributed-teams`}
                onClickFadeOut={true}
                ignoreScrollTop={true}
              ></PLink>
            </li>
            <li>
              <PLink to={`/`} onClickFadeOut={true} ignoreScrollTop={true}>
                The Next Evolution of Outsourcing
              </PLink>
            </li>
          </ul>
          <div class="line-expertise"></div>
          <div>
            <div className="icon-swipe"></div>
          </div>
        </div>
      )
    }
    return <BannerContent />
  }
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <DistributedTeams location={props.location} data={data} {...props} />
    )}
  />
)
