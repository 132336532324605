import bg0 from "../../content/assets/expertisecovers/videos/distri_teams.mp4"
import bg1 from "../../content/assets/expertisecovers/videos/dotnet.mp4"
// import bg1 from "../../content/assets/expertisecovers/videos/test.webm"

import bg2 from "../../content/assets/expertisecovers/videos/AWS.mp4"
import bg3 from "../../content/assets/expertisecovers/videos/NodeJS.mp4"
import bg4 from "../../content/assets/expertisecovers/videos/Angular.mp4"

import bg5 from "../../content/assets/expertisecovers/videos/DevOps.mp4"
import bg6 from "../../content/assets/expertisecovers/videos/Android_iOS.mp4"
import bg7 from "../../content/assets/expertisecovers/videos/Java.mp4"
import bg8 from "../../content/assets/expertisecovers/videos/PHP_Wordpress.mp4"
import bg9 from "../../content/assets/expertisecovers/videos/React.mp4"
import bg10 from "../../content/assets/expertisecovers/videos/Salesforce.mp4"
import bg11 from "../../content/assets/expertisecovers/videos/csharp.mp4"

import mbg0 from "../../content/assets/expertisecovers/m-videos/dteams.mp4"
import mbg1 from "../../content/assets/expertisecovers/m-videos/Net-Iphone.mp4"
import mbg2 from "../../content/assets/expertisecovers/m-videos/AWS-Iphone.mp4"
import mbg3 from "../../content/assets/expertisecovers/m-videos/NodeJS-Iphone.mp4"
import mbg4 from "../../content/assets/expertisecovers/m-videos/Angular-Iphone.mp4"
import mbg5 from "../../content/assets/expertisecovers/m-videos/DevOps-Iphone.mp4"
import mbg6 from "../../content/assets/expertisecovers/m-videos/Android_iOS-Iphone.mp4"
import mbg7 from "../../content/assets/expertisecovers/m-videos/Java-Iphone.mp4"
import mbg8 from "../../content/assets/expertisecovers/m-videos/PHP_Wordpress-Iphone.mp4"
import mbg9 from "../../content/assets/expertisecovers/m-videos/React-Iphone.mp4"
import mbg10 from "../../content/assets/expertisecovers/m-videos/Salesforce-Iphone.mp4"
import mbg11 from "../../content/assets/expertisecovers/m-videos/csharp.mp4"

const imports = {
  bg0: bg0,
  bg1: bg1,
  bg2: bg2,
  bg3: bg3,
  bg4: bg4,
  bg5: bg5,
  bg6: bg6,
  bg7: bg7,
  bg8: bg8,
  bg9: bg9,
  bg10: bg10,
  bg11: bg11,
  mbg0: mbg0,
  mbg1: mbg1,
  mbg2: mbg2,
  mbg3: mbg3,
  mbg4: mbg4,
  mbg5: mbg5,
  mbg6: mbg6,
  mbg7: mbg7,
  mbg8: mbg8,
  mbg9: mbg9,
  mbg10: mbg10,
  mbg11: mbg11,
}

export default imports
